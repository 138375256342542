import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import { css } from '@emotion/react'
import '../styles/reset.css';
import '../styles/fonts.css';
import '../styles/base.css';
import '../styles/bootstrap-grid.min.css';
import FooterBg from '../images/footer-bg.jpg';
import LinkedIn from '../images/linked.svg';
import Twitter from '../images/twiter.svg';
import Insta from '../images/insta.svg';
import Dribbble from '../images/drible.svg';
import LinkedInC from '../images/linked-c.svg';
import TwitterC from '../images/twiter-c.svg';
import InstaC from '../images/insta-c.svg';
import DribbbleC from '../images/drible-c.svg';
import HomeIconLogo from '../images/logo.svg'
import DefaultcardHover from '../images/defaultcard-hover.jpg'
import Lead from '../images/me.jpg'
import { StaticImage } from "gatsby-plugin-image";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { TextPlugin } from "gsap/TextPlugin";
import { Link } from 'gatsby';

const mq = {
  sm: `@media (min-width: ${576}px)`,
  md: `@media (min-width: ${768}px)`,
  lg: `@media (min-width: ${992}px)`,
  xl: `@media (min-width: ${1200}px)`
};

const scroller = (id) => () => {
  document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
}

gsap.registerPlugin(ScrollTrigger);
// gsap.registerPlugin(TextPlugin);
let tl;

const ContactMe = () => {
  return (
    <div className='df mt-auto flex-wrap flex-md-nowrap ' css={css`
      a {
        cursor: pointer;
        text-decoration: none;
        display: flex;
        align-items: center;
        > img:last-child {
          opacity: 0;
          position: absolute;
          z-index: 10;
          transition: opacity .2s;
        }
        &:hover > img:last-child {
          opacity: 1;
        }
        ${mq.md} {
          margin-right: 15px;
        }
      }
      `}>
      <a href='mailto:Theabolfazl@gmail.com' target="_blank"
        className='my-4 my-md-0'
        css={css`
          width: 100%;
          ${mq.md} {
            max-width: 175px;
          }
      `}>
        <button id='cta2'
          className='mt-lg-auto'
          css={css`
          width: 100%;
          font-family: 'Open Sauce One';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.01em;
          background: #1D1D1D;
          color: #fff;
          border: none;
          outline: none;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          cursor: pointer;
          transition: background .2s;
          &:hover {
            background: #353434;
          }
        `}>
          Contact me
        </button></a>
      <div className='df justify-content-between justify-content-md-start' css={css`
        width: 100%;
        min-width: 200px;
      `}>
        <a href='https://dribbble.com/albofazl' target="_blank">
          <img src={Dribbble} alt='' />
          <img src={DribbbleC} alt='' /></a>
        <a href='https://instagram.com/albofazl' target="_blank">
          <img src={Insta} alt='' />
          <img src={InstaC} alt='' /></a>
        <a href='https://twitter.com/aboghanbari' target="_blank">
          <img src={Twitter} alt='' />
          <img src={TwitterC} alt='' /></a>
        <a href='https://www.linkedin.com/in/albofazl/' target="_blank">
          <img src={LinkedIn} alt='' />
          <img src={LinkedInC} alt='' /></a>
      </div>
    </div>
  )
}

function Index() {
  useEffect(() => {
    document.title = 'Abo Ghanbari';
    window.addEventListener("focus", () => {
      document.title = 'Abo Ghanbari';
    });
    window.addEventListener("blur", () => {
      document.title = 'Hey there';
    });
    document.head.append(
      <script
        defer
        src='https://unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js'
      />
    )
  }, []);

  useLayoutEffect(() => {
    // return;
    gsap.fromTo('#header', { opacity: 0, transform: 'translateY(-30px)' }, { opacity: 1, transform: 'translateX(0px)', duration: 1 });
    gsap.to('.container', {opacity: 1})
    tl = gsap.timeline({ defaults: { duration: .75, zIndex: 10 } })
      .fromTo(['#right'],
        { opacity: 0 },
        { opacity: 1 }
      )
      .fromTo(['#left'],
        { opacity: 0, transform: 'translateY(60px) scale(0.95)' },
        { opacity: 1, transform: 'translateX(0px)' }
      )
      .fromTo('#projects',
        { opacity: 0, transform: 'translateY(10px) scale(0.95)' },
        { opacity: 1, transform: 'translateX(0px)' }
      )
      // .to('#title', {duration: 2, text: "Hello, I’m Abo, a product Designer With 7 years of experience.", ease: "none"})
    tl.pause();
    setTimeout(() => {
      tl.play()
    }, 2000);
  }, []);

  return (
    <div className='px-lg-5 container width-crop' style={{opacity: 0}}>
      <Header />
      <img src={Lead} alt='' 
        onLoad={()=> tl.play()}
        css={css`
        position: fixed;
        z-index: -99999;
        transform: scale(0);
        opacity: 0;
      `} />
      <main css={css`
        position: relative;
      `}>
        <section
          id='s1'
          className='df flex-column-reverse flex-md-row'
          css={css`
        `}>
          <div id='left' className='df fd-column col-lg-7d' css={css`
            border-radius: 25px;
            margin: 10px;
            padding: 40px;
            position: relative;
            overflow: hidden;
            @keyframes loop {
              from {
                transform: rotate(70deg) scale(1.8);
              }
            }
            &:hover > img  {
              animation: loop 1.2s infinite alternate-reverse forwards;
            }
            ${mq.md} {
              min-width: 500px;
            }
            ${mq.lg} {
              max-width: 700px;
              padding-right: 100px;
            }
          `}
          >
            <img src={DefaultcardHover} alt='' css={css`
              transform-origin: center center;
              transform: rotate(-10deg) scale(1.3);
              position: absolute;
              z-index: -1;
              top: 0;
              right: 0;
            `} />
            <h1 id='title' css={css`
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 116.7%;
              letter-spacing: -0.03em;
              /* or 28px */
              ${mq.md} {
                font-size: 40px;
                line-height: 47px;
              }
            `}>
              Hello, I’m Abo, a product Designer With 7 years
              of experience.
            </h1>
            <p
              className='mt-lg-5 mt-4 mt-lg-0'
              css={css`
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 151.34%;
            `}>
              I care a lot about using design for positive impact. and enjoy creating user-centric, delightful, and human experiences.
            </p>
            <ContactMe />
          </div>
          <img src={Lead}
            id='right'
            alt="Abolfazl ghanbari"
            loading='eager'
            css={css`
                border-radius: 24px;
                margin: 10px;
                object-fit: cover;
                width: calc( 100% - 20px );
                height: 40vh;
                min-height: 300px;
                max-height: 400px;
                ${mq.md} {
                  width: calc( 40vw - 40px );
                  min-width: 200px;
                  max-width: 460px;
                  height: 500px;
                  max-height: 570px;
                }
              `}
            onLoad={()=> tl.play()}
          />
        </section>
        <Section2 />
      </main>
      <Footer />
    </div>
  );
};

function Section2() {
  return (
    <section id='projects' className='df flex-column flex-md-row flex-wrap'>
      <Card
        ImgComp={() => <StaticImage src='../images/projects/Flop.png' />}
        title={'Flop Application'}
        desc='Social media for poker players'
      />
      <Card
        ImgComp={() => <StaticImage src='../images/projects/Lendscape.png' />}
        title={'Lenscpae'}
        desc='Lend and borrow dashboard'
      />
      <Card
        ImgComp={() => <StaticImage src='../images/projects/Tribe.png' />}
        title={'Tribe.so Admin onboarding'}
        desc='increase user engagement'
      />
      <Card
        ImgComp={() => <StaticImage src='../images/projects/Promot3.png' />}
        title={'Promot3 Dashboard'}
        desc='Project management app'
      />
      <Card
        ImgComp={() => <StaticImage src='../images/projects/Baffy.png' />}
        title={'Baffy'}
        desc='Dating app'
      />
      <Card
        ImgComp={() => <StaticImage src='../images/projects/Crypto.png' />}
        title={'Cryptologist'}
        desc='Analyze crypto currency'
      />
    </section>
  )
}

function Card({ ImgComp, title, desc, href = '/protected' }) {
  const ref = useAnimation();

  return (
    <a ref={ref} href={`${href}?project=${title}`} className='df fd-column' target="_blank"
      css={css`
        margin: 10px 10px;
        border-radius: 25px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        > div {
          transition: transform .8s;
          transform-origin: center 0;
        }
        &:hover > div {
          transform: scale(1.05);
        }
        ${mq.md} {
          width: calc(50% - 20px);
        }
    `}>
      <ImgComp />
      <div css={css`
        position: absolute;
        display: none;
      `}>
        <b css={css`
          margin-top: 25px;
          margin-bottom: 5px;
          font-family: 'Open Sauce One';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.01em;
        `}><span css={css`font-weight: 500;margin-right: 5px`}>{title}</span></b>
        <p css={css`
          font-family: 'Open Sauce One';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: flex-end;
          letter-spacing: -0.01em;
          color: #626060;
          opacity: 0.86;
        `}>{desc}</p>
      </div>
    </a>
  )
}

export function Header() {
  const [open, setOpen] = useState(false);
  function setIsOpenProxy(newValue) {
    setOpen(newValue);
    document.body.style.overflow = newValue ? 'hidden' : 'auto';
  }

  return (
    <header id='header' css={css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      border-radius: 133.44px;
      z-index: 3000;
      height: 75px;
      ${mq.md} {
        height: unset;
        padding: 5px 0;
        margin-top: 10px;
      }
    `}>
    <div
        onClick={() => setIsOpenProxy(false)}
        style={{ pointerEvents: open ? 'auto' : 'none' }}
        className='d-flex fd-column d-md-none'
        css={ css`
          position: fixed;
          width: 100vw;
          height: 100vh;
          right: 0;
          left: 0;
          bottom: 0;
          top: 75px;
          background: #fff;
          /* backdrop-filter: blur(10px); */
          opacity: ${open? .99 : 0};
          transition: transform .25s ease, opacity 0.2s;
          transform-origin: right top;
          transform: translate(${open? 0 : '100%'});
          z-index: 2000;
          > a, > a:visited {
            text-decoration: none;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.01em;
            margin-top: 30px;
            margin-left: 20px;
            text-transform: capitalize;
            color: #000;
          }
        `}
      >
        <Link activeStyle={{ color: '#000' }} to='/'>Home</Link>
        <Link activeStyle={{ color: '#000' }} to='/#projects' onClick={scroller('projects')}>Projects</Link>
        <Link css={css`margin-right:20px`} activeStyle={{ color: '#000' }} to='/#contact' onClick={scroller('contact')}>Contact</Link>
      </div>
      <a href='/' className='df aic'>
        <img src={HomeIconLogo} css={css`
        margin-left: 20px;
        `} />
      </a>
      <button className={cx('menu d-md-none', open && 'opened')} onClick={() => setIsOpenProxy(!open)}>
        <svg width="100" height="100" viewBox="0 0 100 100">
          <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
          <path class="line line2" d="M 20,50 H 80" />
          <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
        </svg>
      </button>
      <nav
        className='d-none d-md-flex'
        css={css`
        display: flex;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.01em;
        text-transform: capitalize;
        > li > a {
          margin-left: 50px;
          text-decoration: none;
          color: #727272;
        }
      `}>
        <li><Link activeStyle={{ color: '#000' }} to='/'>Home</Link></li>
        <li><Link activeStyle={{ color: '#000' }} to='/#projects' onClick={scroller('projects')}>Projects</Link></li>
        <li><Link css={css`margin-right:20px`} activeStyle={{ color: '#000' }} to='/#contact' onClick={scroller('contact')}>Contact</Link></li>
      </nav>
    </header>
  )
}

function Footer() {
  const ref = useAnimation();
  return (
    <footer id='contact' ref={ref}>
      <div className='df fd-column' css={css`
        min-height: 350px;
        border-radius: 25px;
        margin: 10px;
        padding: 40px;
        position: relative;
        overflow: hidden;
        background: #F1F1F1;
        backdrop-filter: blur(10px);
        ${mq.lg} {
          padding-right: 50%;
        }
      `}
      >
        <img src={FooterBg} alt='' css={css`
          transform-origin: center center;
          transform: rotate(-10deg) scale(1.3);
          position: absolute;
          z-index: -1;
          top: 0;
          right: 0;
        `} />
        <h1  css={css`
          font-weight: 600;
          font-size: 40px;
          line-height: 47px;
          letter-spacing: -0.03em;
        `}>
          Want to work together?
        </h1>
        <p
          className=' mt-lg-3 mb-lg-3 mt-5 mt-lg-0'
          css={css`
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 151.34%;
        `}>
          Feel free to reach out for collaborations or just a friendly hello
        </p>
        <a href={`mailto:Theabolfazl@gmail.com`} style={{ textDecoration: 'none', color: '#000' }}>
          Theabolfazl@gmail.com
        </a>
        <ContactMe />
      </div>
    </footer>
  )
}

export default Index;

export function useAnimation() {
  const ref = useRef();
  useLayoutEffect(() => {
    let gs = gsap.from(ref.current, {
      scrollTrigger: {
        trigger: ref.current,
        scroller: 'body',
        start: 'top 100%',
        end: 'top 80%',
        scrub: true,
        /* markers: true */
      },
      opacity: 0,
      translateY: 35,
      transformOrigin: 'bottom',
      duration: .5
    })
    return () => {
      gs.kill()
    };
  }, []);

  return ref;
};

function cx(...rest) {
  return rest.join(' ');
}